const reviewsSlider = async($block) => {
	const { Swiper } = await import( 'swiper' );
	const { Pagination, Navigation } = await import( 'swiper/modules' );
	await import( 'swiper/css/bundle' );

	const $sliderWrap = $block.find('.slider-reviews-wrap');

	if ( !$sliderWrap.length ) { return false; }

	const $slider = $block.find('.slider-reviews');
	const $buttonNext = $sliderWrap.find('.slider-nav__next');
	const $buttonPrev = $sliderWrap.find('.slider-nav__prev');
	const $pagination = $sliderWrap.find('.slider-pagination');

	const reviewsSwiper = new Swiper($slider[0], {
		modules: [Navigation, Pagination],
		init: false,
		slidesPerView: 1,
		spaceBetween: 80,
		speed: 500,
		navigation: {
			nextEl: $buttonNext[0],
			prevEl: $buttonPrev[0]
		},
		pagination: {
			el: $pagination[0],
			clickable: true
		},
	});

	reviewsSwiper.init();
};

themeUtils.loadBlock( reviewsSlider, 'reviews', '.section-reviews' );
